import React from 'react'
import '../css/Button.scss'
//import { BiSun} from "react-icons/bi";
//<BiSun/>

const Button = () => {
  return (
    <button
    type='submit'
    className='button'
    >
      <a href='/admission'>Enroll Now</a>
        
    </button>
  )
}

export default Button
